import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import FileAdd from "assets/Images/file-add.png";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { getInfoCarByImage } from "../MainBody/api";
import "./styles.css";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { StatusCodes } from "http-status-codes"

const MAX_FILE_SIZE = 5 * 1024 * 1024;

const ImageUploadingFromLib = ({
  setDataFromImage,
  resetSelect,
  setIsLoadImg,
  isLoadImg,
  files,
  setFiles,
}: {
  setDataFromImage: Function;
  resetSelect: Function;
  setIsLoadImg: Function;
  isLoadImg: boolean;
  files: any;
  setFiles: Function;
}) => {
  const { t } = useTranslation();

  const formatFileSize = (bytes: number) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": ['.jpg', '.jpeg'],
      'image/png': ['.png']
    },
    maxFiles: 1,
    maxSize: MAX_FILE_SIZE,
    onDrop: async (acceptedFiles, rejectedFiles) => {
      if(rejectedFiles.length > 0) {
        const fileRejectionErrors = rejectedFiles[0].errors.map(error => {
          switch(error.code) {
            case 'file-invalid-type': {
              return t("upload.notification.invalid-type");
            }

            case 'file-too-large': {
              return t("upload.notification.file-too-large", {
                maxSize: formatFileSize(MAX_FILE_SIZE),
                fileSize: formatFileSize(rejectedFiles[0].file.size)
              });
            }
            case 'too-many-files':
              return t("upload.notification.too-many-files");
            default:
              return t("upload.notification.server-error");

          }
        })

        toast.error(fileRejectionErrors[0], {
          position: "top-center",
          autoClose: 3000,
        });
        return;
      }

      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      resetSelect();


      const formData = new FormData();
      const file = acceptedFiles[0];
      formData.append("img", file);

      setIsLoadImg(true);
      try {
        const getByImgApi = await getInfoCarByImage(formData);
        const getByImg = getByImgApi?.codebookPredictResult;
        const { carCompany, carModel, carVersion, manufactureYear, vehicleInspectionOcrId } = getByImg;

        if (getByImg.carCompany) {
          // toast.success(t("upload.notification.success"), {
          //   position: "top-center",
          //   autoClose: 3000,
          // });
          setDataFromImage({
            predictedCarCompany: carCompany,
            predictedCarModel: carModel,
            predictedYear: manufactureYear,
            predictedCarVersion: carVersion,
            vehicleInspectionOcrId
          });
          setIsLoadImg(false);
        } else {
          toast.success(t("upload.notification.server-not-found-codebook"), {
            position: "top-center",
            autoClose: 3000,
          });
          setIsLoadImg(false);
        }
      } catch (error: any) {
        const errorStatusCode = _.get(error, 'response.status');

        if (errorStatusCode === StatusCodes.NOT_FOUND) {
          toast.error(`${t("upload.notification.server-not-found-error")}`, {
            position: "top-center",
            autoClose: 3000,
          });
        } else {
          toast.error(`${t("upload.notification.server-error")}`, {
            position: "top-center",
            autoClose: 3000,
          });
        }

        setIsLoadImg(false);
      }
    },
  });

  const handleClose = (e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
    e.stopPropagation();
    resetSelect();
    setFiles([]);
  };

  return (
    <div>
      <section className="container">
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          {files.length === 0 ? (
            <>
              <img src={FileAdd} alt=""></img>
              <p className="textCustom">{t("form.registry.title")}</p>
              <p className="textCustomGray">{t("form.registry.description")}</p>
              <p className="textCustomGray">{t("form.registry.file-format")}</p>
            </>
          ) : (
            <>
              <div style={{ display: "flex" }}>
                <img
                  src={files[0].preview}
                  style={{ height: "50px", width: "50px" }}
                  alt=""
                  onLoad={() => {
                    URL.revokeObjectURL(files[0].preview);
                  }}
                />
                <div
                  style={{
                    width: "calc(100% - 90px)",
                    padding: "10px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <span>{files[0].name}</span>
                  {isLoadImg ? <LinearProgress /> : null}
                </div>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                  onClick={(e) => {
                    handleClose(e);
                  }}
                  style={{ height: 50, width: 50 }}
                >
                  <HighlightOffIcon />
                </IconButton>
              </div>
              {isLoadImg ? null : (
                <span className="textSuggest">
                  ({t("form.registry.dropdown-description")})
                </span>
              )}
            </>
          )}
        </div>
      </section>
    </div>
  );
};

export default ImageUploadingFromLib;
