/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import { memo, useState, useRef } from "react";
import { Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CarForm from "./CarType/Car";
import TruckForm from "./CarType/Truck";
import CoachForm from "./CarType/Coach";
import SpecializedVehicleForm from "./CarType/SpecializedVehicle";
import SelectedInput from "Components/FormComponents/SelectedInput";
import {
  getPriceOnRoad,
  getPriceVolatility,
  getCarEstimatePrice,
  getPrice,
  getPriceV3,
} from "../../MainBody/api";
import {
  ResultCarPrice,
  onRoadPriceResult,
  CarPriceInflation,
  carEstimatePrice,
} from "../types";
import { passParamIntoCarValue } from "Common/common";
import { Form, Formik } from "formik";

export interface CarFormRef {
  resetVehicleInspectionOcrId: () => void;
}

const FormAICycle = ({
  setCarPriceValue,
  setOnRoadPrice,
  setPriceVolatility,
  setCarEstimatedPrice,
  setIsLoadCarPrice,
  setError,
  setErrorVolatility,
  setErrorEstimated,
}: {
  setCarPriceValue: React.Dispatch<React.SetStateAction<ResultCarPrice>>;
  setOnRoadPrice: React.Dispatch<React.SetStateAction<onRoadPriceResult>>;
  setPriceVolatility: React.Dispatch<React.SetStateAction<CarPriceInflation[]>>;
  setCarEstimatedPrice: React.Dispatch<React.SetStateAction<carEstimatePrice>>;
  setIsLoadCarPrice: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorVolatility: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorEstimated: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const theme = useTheme();
  const [dataStringify, setDataStringify] = useState<string>("");
  const typeLocal = localStorage.getItem("type");
  const value = !typeLocal ? "car" : typeLocal;
  const [type, setType] = useState(value);
  const carFormRef = useRef<CarFormRef>(null);


  const options = [
    { id: "car", label: "Xe con" },
    { id: "truck", label: "Xe chở hàng hoá" },
    { id: "coach", label: "Xe khách" },
    { id: "specialized", label: "Xe chuyên dụng" },
  ];

  const handleValueChange = (value: string) => {
    setDataStringify(value);
  };

  const initialValues = {
    carCompanyId: "",
  };

  /* event click button Tra cứu */
  const handleSubmit = async () => {
    localStorage.setItem(type, dataStringify);

    const object = JSON.parse(dataStringify);

    const {
      company,
      model,
      manufactureyear,
      version,
      optionKey,
      originId,
      kmTraveled,
    } = JSON.parse(dataStringify);


    if (type === "car") {
      try {
        //Run api evaluate car price
        setIsLoadCarPrice(true);

        const valueGetCarPrice: any = passParamIntoCarValue(
          company,
          model,
          manufactureyear,
          version,
          optionKey,
          originId,
          kmTraveled
        )

        const vehicleInspectionOcrId = _.get(object, 'vehicleInspectionOcrId')

        if (vehicleInspectionOcrId) {
          _.set(valueGetCarPrice, 'vehicleInspectionOcrId', vehicleInspectionOcrId)
        }

        const getCarPrice = await getPrice(
          valueGetCarPrice
        );
        setTimeout(() => setIsLoadCarPrice(false), 1000);
        setCarPriceValue(getCarPrice);

        if (carFormRef.current) {
          carFormRef.current.resetVehicleInspectionOcrId();
        }

      } catch (error) {
        console.log(error);
        setIsLoadCarPrice(false);
      }

      // get api chart price car for current year and next 3 year
      try {
        const volatilityPrice = await getPriceVolatility(
          version.key ? version.key : ""
        );
        setPriceVolatility(volatilityPrice);
        setErrorVolatility(false);
      } catch (error) {
        setErrorVolatility(true);
      }

      // average price next 3 year
      try {
        const carEstimatedPrice = await getCarEstimatePrice(
          version.key ? version.key : ""
        );
        setCarEstimatedPrice(carEstimatedPrice);
        setErrorEstimated(false);
      } catch (error) {
        setErrorEstimated(true);
      }

      // get car price onroad
      try {
        const value = await getPriceOnRoad(
          version.key!,
          "hanoi",
          optionKey.key
        );
        setOnRoadPrice(value);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        //Run api evaluate car price
        setIsLoadCarPrice(true);
        const getCarPrice = await getPriceV3(
          object.modelCode.id === "other"
            ? localStorage.getItem("type") === "truck"
              ? {
                  vehicleTypeKey: localStorage.getItem("type"),
                  companyName: object.company.id,
                  versionName: object.model.id,
                  modelCode: "khac",
                  manufactureYear: object.selectedManufactured.id,
                  modelCodeFromUser: object.otherValue,
                  barrelTypeKey: object.selectedBarrelType.id,
                }
              : {
                  vehicleTypeKey: localStorage.getItem("type"),
                  companyName: object.company.id,
                  versionName: object.model.id,
                  modelCode: "khac",
                  manufactureYear: object.selectedManufactured.id,
                  modelCodeFromUser: object.otherValue,
                }
            : localStorage.getItem("type") === "truck"
            ? {
                vehicleTypeKey: localStorage.getItem("type"),
                companyName: object.company.id,
                versionName: object.model.id,
                modelCode: object.modelCode.id,
                manufactureYear: object.selectedManufactured.id,
                barrelTypeKey: object.selectedBarrelType.id,
              }
            : {
                vehicleTypeKey: localStorage.getItem("type"),
                companyName: object.company.id,
                versionName: object.model.id,
                modelCode: object.modelCode.id,
                manufactureYear: object.selectedManufactured.id,
              }
        );
        setTimeout(() => setIsLoadCarPrice(false), 1000);
        setCarPriceValue({
          carCompany: "",
          carModel: "",
          carVersion: "",
          year: "",
          maxPrice: getCarPrice.maxPrice,
          carValue: getCarPrice.price,
          minPrice: getCarPrice.minPrice,
          isWarning: false,
          listedPrice: null,
          minListedPrice: null,
          maxListedPrice: null,
          hanoiOnRoadPrice: null,
          generalOnRoadPrice: null,
          hcmOnRoadPrice: null,
          additions: [],
        });
      } catch (error) {
        console.log(error);
        setIsLoadCarPrice(false);
      }
    }
  };

  return (
    <Formik
      onSubmit={() => {
        handleSubmit();
      }}
      initialValues={initialValues}
    >
      {() => {
        return (
          <Form>
            <Grid
              container
              p={1}
              gap={2}
              sx={{
                backgroundColor:
                  theme.palette.mode === "dark" ? "#21262C" : "unset",
              }}
            >
              <SelectedInput
                label="Loại xe"
                idForInput="carType"
                listOption={options}
                value={type}
                onChange={setType}
              />
              {type === "car" && (
                <CarForm
                  ref={carFormRef}
                  setCarPriceValue={setCarPriceValue}
                  setOnRoadPrice={setOnRoadPrice}
                  setPriceVolatility={setPriceVolatility}
                  setCarEstimatedPrice={setCarEstimatedPrice}
                  setIsLoadCarPrice={setIsLoadCarPrice}
                  setError={setError}
                  setErrorVolatility={setErrorVolatility}
                  setErrorEstimated={setErrorEstimated}
                  onValueChange={handleValueChange}
                />
              )}
              {type === "truck" && (
                <TruckForm
                  setCarPriceValue={setCarPriceValue}
                  setOnRoadPrice={setOnRoadPrice}
                  setPriceVolatility={setPriceVolatility}
                  setCarEstimatedPrice={setCarEstimatedPrice}
                  setIsLoadCarPrice={setIsLoadCarPrice}
                  setError={setError}
                  setErrorVolatility={setErrorVolatility}
                  setErrorEstimated={setErrorEstimated}
                  onValueChange={handleValueChange}
                />
              )}
              {type === "specialized" && (
                <SpecializedVehicleForm
                  setCarPriceValue={setCarPriceValue}
                  setOnRoadPrice={setOnRoadPrice}
                  setPriceVolatility={setPriceVolatility}
                  setCarEstimatedPrice={setCarEstimatedPrice}
                  setIsLoadCarPrice={setIsLoadCarPrice}
                  setError={setError}
                  setErrorVolatility={setErrorVolatility}
                  setErrorEstimated={setErrorEstimated}
                  onValueChange={handleValueChange}
                />
              )}
              {type === "coach" && (
                <CoachForm
                  setCarPriceValue={setCarPriceValue}
                  setOnRoadPrice={setOnRoadPrice}
                  setPriceVolatility={setPriceVolatility}
                  setCarEstimatedPrice={setCarEstimatedPrice}
                  setIsLoadCarPrice={setIsLoadCarPrice}
                  setError={setError}
                  setErrorVolatility={setErrorVolatility}
                  setErrorEstimated={setErrorEstimated}
                  onValueChange={handleValueChange}
                />
              )}
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default memo(FormAICycle);
