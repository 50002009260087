import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { ToastContainer } from "react-toastify";


import { useState, useRef, useEffect } from "react";
import textPanner from "../../assets/Images/textPaner.png";
import textDeco from "../../assets/Images/ text-deco.png";
import FormAICycle from "./Form";
import Header from "./header";
import useCheckLogin from "hooks/useCheckLogin";
import useCheckTabActivityDetector from "hooks/useCheckActiveTab";
import "./style.css";

export type ResultCarPrice = {
  carCompany: string;
  carModel: string;
  carVersion: string;
  year: string;
  maxPrice: number | null;
  carValue: number;
  minPrice: number | null;
  isWarning: boolean;
  listedPrice: number | null;
  minListedPrice: number | null;
  maxListedPrice: number | null;
  hanoiOnRoadPrice: number | null;
  generalOnRoadPrice: number | null;
  hcmOnRoadPrice: number | null;
  additions: Addition[];
};

export interface Addition {
  id: number;
  carVersionKey: string;
  carFuel: string;
  listedPrice: number;
  price: number;
  hanoiOnRoadPrice: number;
  generalOnRoadPrice: number;
  hcmOnRoadPrice: number;
  carValue: number;
  minPrice: number;
  maxPrice: number;
  minListedPrice: number;
  maxListedPrice: number;
  optionName: string | null;
  optionKey: string | null;
}

export default function SearchPage() {
  const [type, setType] = useState<string>("car");
  const gridRef = useRef<HTMLDivElement>(null);
  const [backgroundHeight, setBackgroundHeight] = useState<string>("100vh");

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const tablet = useMediaQuery(theme.breakpoints.down("md"));

  useCheckLogin();
  useCheckTabActivityDetector();

  const url = `url(${
    theme.palette.mode === "light"
      ? matches
        ? "https://dyta7vmv7sqle.cloudfront.net/VALUE-ME/background-phone.png"
        : tablet
        ? "https://dyta7vmv7sqle.cloudfront.net/VALUE-ME/background-tablet.png"
        : "https://dyta7vmv7sqle.cloudfront.net/VALUE-ME/background.png"
      : "https://dyta7vmv7sqle.cloudfront.net/VALUE-ME/car-darkmode.png"
  })`;

  const backgroundImage =
    type === "car"
      ? url
      : type === "truck"
      ? theme.palette.mode === "light"
        ? `url('https://dyta7vmv7sqle.cloudfront.net/VALUE-ME/truckbackground.png')`
        : `url(https://dyta7vmv7sqle.cloudfront.net/VALUE-ME/truck-darkmode.png)`
      : type === "coach"
      ? theme.palette.mode === "light"
        ? `url(https://dyta7vmv7sqle.cloudfront.net/VALUE-ME/coachBackground.png)`
        : `url(https://dyta7vmv7sqle.cloudfront.net/VALUE-ME/truck-darkmode.png)`
      : theme.palette.mode === "light"
      ? `url(https://dyta7vmv7sqle.cloudfront.net/VALUE-ME/specialVehicleBackground.png)`
      : `url(https://dyta7vmv7sqle.cloudfront.net/VALUE-ME/truck-darkmode.png)`;

  const handleReceiveType = (value: string) => {
    setType(value);
  };

  useEffect(() => {
    const updateBackgroundHeight = () => {
      if (gridRef.current) {
        const gridHeight = gridRef.current.offsetHeight;
        const viewportHeight = window.innerHeight;
        setBackgroundHeight(`${Math.max(gridHeight + 200, viewportHeight)}px`);
      }
    };

    // Initial calculation
    updateBackgroundHeight();

    const currentElement = gridRef.current;

    // Add resize observer to watch for grid height changes
    const resizeObserver = new ResizeObserver(updateBackgroundHeight);
    if (currentElement) {
      resizeObserver.observe(currentElement);
    }

    // Cleanup
    return () => {
      if (currentElement) {
        resizeObserver.unobserve(currentElement);
      }
    };
  }, []);


  return (
    <>
      <div className="page">
          <ToastContainer />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              backgroundImage: backgroundImage,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              minHeight: backgroundHeight,
            }}
          >
            <Header />
            <Grid container sx={{ mt: 7 }}>
              <Grid
                item
                xs={12}
                sm={12}
                xl={6}
                lg={6}
                flexDirection="column"
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap={3}
              >
                <img src={textPanner} alt="" width="50%" />
                <img src={textDeco} alt="" width="50%" />
              </Grid>
            </Grid>
          </Box>
        </div>
        <Grid
          ref={gridRef}
          container
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            px: matches ? 3 : 6,
            pt: matches ? 2 : 10
          }}
        >
          <Grid item xs={12} sm={12} xl={6} lg={6}>
            <FormAICycle onSendType={handleReceiveType} />
          </Grid>
        </Grid>
    </>
  );
}
