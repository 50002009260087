/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo, useRef, forwardRef, useImperativeHandle } from "react";
import _ from "lodash";
import {
  Grid,
  TextField,
  Typography,
  FormControl,
  useMediaQuery,
  InputAdornment,
  FormHelperText,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import IconExpandInformation from "../../icons/IconExpandInformation";
import IconHideInfomation from "../../icons/IconHideInfomation";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AutoCompleteInput from "../../../../Components/FormComponents/AutoCompleteInput";
import ImageUploadingFromLib from "Components/ImageUploading";
import {
  getDataFromLocalStorage,
  passParamIntoCarValueLocal,
} from "Common/common";
import {
  getCarInfoCompany,
  getCarInfoModel,
  getCarManufacturedYears,
  getCarVersion,
  getListOrigin,
  getKmTraveledSuggestion,
  getCarOptionByVersion,
  getPriceOnRoad,
  getPriceVolatility,
  getCarEstimatePrice,
  getPrice,
} from "../../../MainBody/api";
import { SelectOption, CarCompanyInfo, CarInfoModel } from "./type";
import {
  ResultCarPrice,
  onRoadPriceResult,
  CarPriceInflation,
  carEstimatePrice,
} from "../../types";

import { useContext } from "react";
import UserContext from "userContext";

import { toast } from "react-toastify";


export interface CarFormRef {
  resetVehicleInspectionOcrId: () => void;
}


const CarForm = forwardRef<CarFormRef, {
  setCarPriceValue: React.Dispatch<React.SetStateAction<ResultCarPrice>>;
  setOnRoadPrice: React.Dispatch<React.SetStateAction<onRoadPriceResult>>;
  setPriceVolatility: React.Dispatch<React.SetStateAction<CarPriceInflation[]>>;
  setCarEstimatedPrice: React.Dispatch<React.SetStateAction<carEstimatePrice>>;
  setIsLoadCarPrice: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorVolatility: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorEstimated: React.Dispatch<React.SetStateAction<boolean>>;
  onValueChange: (value: string) => void;
}>((props, ref) => {
  const {
    setCarPriceValue,
    setOnRoadPrice,
    setPriceVolatility,
    setCarEstimatedPrice,
    setIsLoadCarPrice,
    setErrorVolatility,
    setErrorEstimated,
    onValueChange,
  } = props;


 

  const isTabletOrMobile = useMediaQuery("(max-width: 1050px)");
  const { t } = useTranslation();
  const theme = useTheme();

  const [listCarCompany, setListCarCompany] = useState<SelectOption[]>([]);
  const [listCarInfoModel, setListCarInfoModel] = useState<SelectOption[]>([]);
  const [listOrigin, setListOrigin] = useState<SelectOption[]>([]);
  const [listVersion, setListVersion] = useState<SelectOption[]>([]);
  const [listManufactured, setListManufactured] = useState<SelectOption[]>([]);
  const [listOption, setListOption] = useState<SelectOption[]>([]);

  const [selectedModel, setSelectedModel] = useState<SelectOption>({
    id: "",
    label: "",
  });
  const [selectedOption, setSelectedOption] = useState<SelectOption>({
    id: "",
    label: "",
  });
  const [selectedVersion, setSelectedVersion] = useState<SelectOption>({
    id: "",
    label: "",
  });
  const [selectedOrigin, setSelectedOrigin] = useState<SelectOption>({
    id: "",
    label: "",
  });
  const [selectedCarCompany, setSelectedCarCompany] = useState<SelectOption>({
    id: "",
    label: "",
  });
  const [onRoad, setOnRoad] = useState<string>("");

  const [selectedManufactured, setSelectedManufactured] =
    useState<SelectOption>({ id: "", label: "" });

  const [openCarModel, setOpenCarModel] = useState(false);
  const [openCarManufactured, setOpenCarManufactured] = useState(false);
  const [openCarVersion, setOpenCarVersion] = useState(false);
  const [openCarOption, setOpenCarOption] = useState(false);
  const [openCarOrigin, setOpenCarOrigin] = useState(false);
  const [expandMore, setExpandMore] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [isLoadDataFrLocal, setIsLoadDataFrLocal] = useState<boolean>(true);

  const [dataFormImage, setDataFromImage] = useState<any>();
  const [isLoadImg, setIsLoadImg] = useState(false);
  const [files, setFiles] = useState<any>([]);
  const [isDoneUploadImageAndFill, setIsDoneUploadImageAndFill] = useState(false)

  const vehicleInspectionOcrIdRef = useRef(0)
  

  const { user } = useContext(UserContext);

  const listHideCarBrand: string[] = useMemo(
    () => [
      "ed70991f-411c-4ac0-8ae4-0c7d66427e59",
      "06df3ccf-b96f-4c95-98fb-801b25295a66",
      "b7b77302-86a5-4d13-a621-d25bb9c48569",
      "f31e885d-dfc5-403c-beb2-da3d0a20c9ce",
      "20d8df48-232c-4711-9eac-b6fbf5d67253",
    ],
    []
  );

  const data = getDataFromLocalStorage();

  const resetSelect = () => {
    setSelectedCarCompany({ id: "", label: "" });
    setSelectedModel({ id: "", label: "" });
    setSelectedVersion({ id: "", label: "", key: "" });
    setSelectedManufactured({ id: "", label: "" });
    // setCorrelationId("");
    setIsDoneUploadImageAndFill(false);
    setDataFromImage(null);
    vehicleInspectionOcrIdRef.current = 0;
  };

   // Expose the reset function through ref to help parent component can call reset below ref
   useImperativeHandle(ref, () => ({
    resetVehicleInspectionOcrId: () => {
      vehicleInspectionOcrIdRef.current = 0;
    }
  }));

  useEffect(() => {
    (async () => {
      const getCarInfo: CarCompanyInfo[] = await getCarInfoCompany(1000);
      let newListOption: SelectOption[] = [];
      if (getCarInfo && getCarInfo.length > 0) {
        getCarInfo.forEach((company) => {
          const option: SelectOption = {
            id: company.carCompanyId,
            label: company.vehicleBrandName,
          };

          newListOption.push(option);
        });
        listHideCarBrand.forEach((element) => {
          newListOption = newListOption.filter(
            (option) => option.id !== element
          );
        });

        setListCarCompany(newListOption);
      }

        if (dataFormImage) {
          const nameOfCarCompany = dataFormImage.predictedCarCompany;
          const findCarCompany = newListOption.find((company) =>
            company?.label?.includes(nameOfCarCompany)
          );

          if (findCarCompany) {
            const vehicleInspectionOcrId = _.get(dataFormImage, 'vehicleInspectionOcrId');
            setSelectedCarCompany(findCarCompany);
            vehicleInspectionOcrIdRef.current = vehicleInspectionOcrId;
          }
        }
    })();
  }, [listHideCarBrand, dataFormImage]);

  useEffect(() => {
    if (selectedCarCompany && selectedCarCompany.id !== "") {
      setSelectedModel({ id: "", label: "" });
      setSelectedVersion({ id: "", label: "" });
      setSelectedManufactured({ id: "", label: "" });
      setSelectedOption({ id: "", label: "" });
      setSelectedOrigin({ id: "", label: "" });
      setListCarInfoModel([]);

      (async () => {
        const listCarInfoModel: CarInfoModel[] = await getCarInfoModel(
          selectedCarCompany?.id || "",
          100
        );
        const newListOption: SelectOption[] = [];

        if (listCarInfoModel && listCarInfoModel.length > 0) {
          listCarInfoModel.forEach((model) => {
            const option: SelectOption = {
              id: model.carModelId,
              label: model.carModelName,
            };
            newListOption.push(option);
          });
          setListCarInfoModel(newListOption);
          if (isLoadDataFrLocal) {
            setSelectedModel(data.model);
            setSelectedOption(data.optionKey);
          }
        }
        if (dataFormImage) {
          const nameOfCarModel = dataFormImage.predictedCarModel;
          const findCarModel = newListOption.find((model) =>
            model?.label?.includes(nameOfCarModel)
          );
          if (findCarModel) {
            setSelectedModel(findCarModel);
          }
        }
      })();
    } else {
      setSelectedModel({ id: "", label: "" });
      setSelectedOption({ id: "", label: "" });
      setSelectedOrigin({ id: "", label: "" });
      setListOption([]);

    }
  }, [selectedCarCompany, dataFormImage]);

  useEffect(() => {
    if (selectedModel && selectedModel.id !== "") {
      setSelectedVersion({ id: "", label: "" });
      setSelectedManufactured({ id: "", label: "" });
      setSelectedOrigin({ id: "", label: "" });
      setListManufactured([]);

      (async () => {
        const listManufacturedYears: number[] = await getCarManufacturedYears(
          selectedCarCompany.id,
          selectedModel.id
        );
        const newListManufactured: SelectOption[] = [];
        if (listManufacturedYears && listManufacturedYears.length > 0) {
          listManufacturedYears.forEach((manufactured) => {
            const option: SelectOption = {
              id: manufactured.toString(),
              label: manufactured.toString(),
            };
            newListManufactured.push(option);
          });
          setListManufactured(newListManufactured);
          if (isLoadDataFrLocal) {
            setSelectedManufactured(data.manufactureyear);
          }
        }
        if (dataFormImage) {
          const nameOfManufactured = dataFormImage.predictedYear;
          const findManufactured = newListManufactured.find(
            (manufactured) =>
              manufactured?.label === nameOfManufactured.toString()
          );
          if (findManufactured) {
            setSelectedManufactured(findManufactured);
          }
        }
      })();
    } else {
      setSelectedManufactured({ id: "", label: "" });
      setSelectedOrigin({ id: "", label: "" });

    }
  }, [selectedModel, selectedCarCompany, dataFormImage]);

  useEffect(() => {
    if (selectedManufactured && selectedManufactured.id !== "") {
      setSelectedVersion({ id: "", label: "" });
      setSelectedOrigin({ id: "", label: "" });
      setListVersion([]);

      (async () => {
        const listVersion = await getCarVersion(
          selectedCarCompany?.id,
          selectedModel.id,
          selectedManufactured.id
        );
        const newListOption: SelectOption[] = [];
        if (listVersion && listVersion.length > 0) {
          listVersion.forEach((version: any) => {
            const option: SelectOption = {
              id: version.carVersionId,
              label: version.carVersionName,
              key: version.carVersionKey,
              fuel: version.carFuelName,
            };
            newListOption.push(option);
          });
          setListVersion(newListOption);
          if (isLoadDataFrLocal) {
            setSelectedVersion(data.version);
          }
        }
        if (dataFormImage) {
          const nameOfCarVersion = dataFormImage.predictedCarVersion;
          const findCarVersion = newListOption.find((version) =>
            version?.label?.includes(nameOfCarVersion)
          );
          if (findCarVersion) {
            setSelectedVersion(findCarVersion);
          }

          setIsDoneUploadImageAndFill(true)
        }
      })();
      (async () => {
        const kmTraveld = await getKmTraveledSuggestion(
          selectedManufactured.id
        );
        setOnRoad(kmTraveld.kmTraveledSuggest);
      })();
    } else {
      setSelectedVersion({ id: "", label: "" });
      setSelectedOrigin({ id: "", label: "" });

    }
  }, [selectedManufactured, selectedModel, selectedCarCompany, dataFormImage]);

  useEffect(() => {
    if (selectedVersion && selectedVersion.id !== "") {
      setSelectedOrigin({ id: "", label: "" });
      setListOrigin([]);
      setListOption([]);

      (async () => {
        const listOrigin = await getListOrigin({
          carCompany: selectedCarCompany.label,
          carModel: selectedModel.label,
          carVersion: selectedVersion.label,
          year: parseInt(selectedManufactured.id, 10),
        });
        const newListOption: SelectOption[] = [];
        if (listOrigin && listOrigin.length > 0) {
          listOrigin.forEach((origin: any) => {
            const option: SelectOption = {
              id: origin.originId,
              label: origin.originName,
            };
            newListOption.push(option);
          });
          setListOrigin(newListOption);
          if (listOrigin.length >= 1) {
            setSelectedOrigin({
              id: listOrigin[0].originId,
              label: listOrigin[0].originName,
            });
          }
          if (isLoadDataFrLocal) {
            setSelectedOrigin(data.originId);
          }
        }
      })();
      (async () => {
        const listCarOption = await getCarOptionByVersion(
          selectedCarCompany.label,
          selectedModel.label,
          selectedVersion.label,
          parseInt(selectedManufactured.id, 10)
        );
        const newListOption: SelectOption[] = [];
        if (listCarOption && listCarOption.length > 0) {
          listCarOption.forEach((item: any) => {
            const option: SelectOption = {
              id: item.optionId,
              label: item.optionName,
              key: item.optionKey,
            };
            newListOption.push(option);
          });
          setListOption(newListOption);
          if (listCarOption.length >= 1) {
            setSelectedOption({
              id: listCarOption[0].optionId,
              label: listCarOption[0].optionName,
            });
          }
          if (isLoadDataFrLocal) {
            setSelectedOption(data.optionKey);
          }
        } else {
          setListOption([]);
        }
      })();
    } else {
      setListOrigin([]);
      setListOption([]);
      setSelectedOrigin({ id: "", label: "" });
      setSelectedOption({ id: "", label: "", key: "" });

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVersion]);

  useEffect(() => {
    if (selectedOrigin === null) {
      setSelectedOrigin({ id: "", label: "" });
    }
  }, [selectedOrigin]);

  useEffect(() => {
    if (selectedOption === null) {
      setSelectedOption({ id: "", label: "", key: "" });
    }
  }, [selectedOption]);

  // useEffect(() => {
  //   if (files.length === 0) {
  //     setCorrelationId("");
  //     return;
  //   }
  //   if (dataFormImage) {
  //     const traceId = dataFormImage.correlationId;

  //     if (traceId) {
  //       setCorrelationId(traceId);
  //     }
  //   }
  // }, [dataFormImage, files.length]);


  //run when component mounted in first time
  useEffect(() => {
    if (data) {
      setSelectedCarCompany(data.company);
      setSelectedOrigin(data.originId);
      setOnRoad(data.kmTraveled);

      const vehicleInspectionOcrId = _.get(data, 'vehicleInspectionOcrId')

      const valueCar: any = passParamIntoCarValueLocal(
        data.optionKey,
        data.originId,
        data.kmTraveled,
        data
      );

      if (vehicleInspectionOcrId) {
        _.set(valueCar, 'vehicleInspectionOcrId', vehicleInspectionOcrId)
      }


      const evaluate = async () => {
        try {
          setIsLoadCarPrice(true);
          const value = await getPrice(valueCar);
          setCarPriceValue(value);
          setTimeout(() => setIsLoadCarPrice(false), 1000);
        } catch (error) {
          setIsLoadCarPrice(false);
        }
      };

      evaluate();

      const getPriceOnRoadApi = async () => {
        try {
          const value = await getPriceOnRoad(
            data.version.key,
            "hanoi",
            data.optionKey?.key
          );
          setOnRoadPrice(value);
        } catch (error) {
          console.log(error);
        }
      };
      getPriceOnRoadApi();

      const getPriceVolatilityApi = async () => {
        try {
          const value = await getPriceVolatility(data.version.key);
          setPriceVolatility(value);
          setErrorVolatility(false);
        } catch (error) {
          setErrorVolatility(true);
        }
      };
      getPriceVolatilityApi();

      const getCarEstimatedApi = async () => {
        try {
          const value = await getCarEstimatePrice(data.version.key);
          setCarEstimatedPrice(value);
          setErrorEstimated(false);
        } catch (error) {
          setErrorEstimated(true);
        }
      };
      getCarEstimatedApi();
    }
  }, []);

  useEffect(() => {
    if (data) {
      if (
        selectedCarCompany &&
        selectedModel &&
        selectedManufactured &&
        selectedVersion &&
        selectedCarCompany.id === data.company.id &&
        selectedModel.id === data.model.id &&
        selectedManufactured.id === data.manufactureyear.id &&
        selectedVersion.id === data.version.id
      ) {
        setIsLoadDataFrLocal(false);
      }
    } else {
      setIsLoadDataFrLocal(false);
    }
  }, [
    selectedCarCompany,
    selectedModel,
    selectedManufactured,
    selectedVersion,
  ]);

  useEffect(() => {
    onValueChange(
      JSON.stringify({
        company: selectedCarCompany,
        model: selectedModel,
        manufactureyear: selectedManufactured,
        version: selectedVersion,
        originId: selectedOrigin,
        kmTraveled: onRoad,
        optionKey: selectedOption,
        vehicleInspectionOcrId: vehicleInspectionOcrIdRef.current
      })
    );
  }, [
    selectedCarCompany,
    selectedModel,
    selectedVersion,
    selectedManufactured,
    selectedOption,
    selectedOrigin,
    onRoad,
  ]);

  //Check upload image vehicle inspection and fill to auto complete done => show message for each case
    useEffect(() => {
      if (isDoneUploadImageAndFill) {
        const idSelectedModel = _.get(selectedModel, 'id');
        const idSelectedManufactured = _.get(selectedManufactured, 'id');
        const idSelectedVersion = _.get(selectedVersion, 'id');
  
  
        if (!idSelectedModel || !idSelectedManufactured || !idSelectedVersion) {
           toast.success(t("upload.notification.server-not-found-codebook"), {
              position: "top-center",
              autoClose: 3000,
            });
        } else {
            toast.success(t("upload.notification.success"), {
              position: "top-center",
              autoClose: 3000,
            });
        }
      }
  
    }, [isDoneUploadImageAndFill])


  const handleChangeOnRoad = (event: React.ChangeEvent<HTMLInputElement>) => {
    const road = parseFloat(event.target.value);
    if (road < 0) {
      setOnRoad("0");
    } else if (road > 999999) {
      setOnRoad("999999");
    } else {
      setOnRoad(event.target.value);
    }
  };

  const isValidate =
    selectedCarCompany &&
    selectedCarCompany.id !== "" &&
    selectedModel &&
    selectedModel.id !== "" &&
    selectedVersion &&
    selectedVersion.id !== "" &&
    selectedManufactured &&
    selectedManufactured.id !== "";

  return (
        <>
      {isTabletOrMobile && (
        <Grid item xs={12} sm={12} md={12}>
          <Button
            variant="outlined"
            endIcon={!open ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            sx={{
              color: theme.palette.mode === "dark" ? "white" : "black",
              border: "1px solid #E5E7EB",
              borderRadius: "8px",
            }}
            onClick={() => setOpen(!open)}
          >
            Tra cứu
          </Button>
        </Grid>
      )}
      {(open || !isTabletOrMobile) && (
        <>
          {user?.ocrEnable ? <Grid item xs={12} sm={5.6} md={12}>
            <ImageUploadingFromLib
              setDataFromImage={setDataFromImage}
              resetSelect={resetSelect}
              setIsLoadImg={setIsLoadImg}
              isLoadImg={isLoadImg}
              files={files}
              setFiles={setFiles}
            />
          </Grid>: null}
          <Grid item xs={12} sm={5.6} md={12}>
            <AutoCompleteInput
              label={t("form.selection.brand.title")}
              listOption={listCarCompany}
              onChange={setSelectedCarCompany}
              value={selectedCarCompany}
              disabled={false}
              placeholder={t("form.selection.brand.placeholder")}
              onClose={() => {
                setOpenCarModel(true);
              }}
              onOpen={() => {
                setOpenCarModel(false);
                setOpenCarVersion(false);
              }}
              //   files={files}
              //   setFiles={setFiles}
            />
          </Grid>

          <Grid item xs={12} sm={5.6} md={12}>
            <AutoCompleteInput
              label={t("form.selection.model.title")}
              listOption={listCarInfoModel}
              onChange={setSelectedModel}
              value={selectedModel}
              disabled={
                selectedCarCompany && selectedCarCompany.id !== ""
                  ? false
                  : true
              }
              placeholder={t("form.selection.model.placeholder")}
              open={
                selectedCarCompany &&
                selectedCarCompany.id !== "" &&
                openCarModel
              }
              onClose={() => {
                setOpenCarModel(false);
                setOpenCarManufactured(true);
              }}
              onOpen={() => {
                setOpenCarModel(!openCarModel);
                setOpenCarManufactured(false);
                setOpenCarVersion(false);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={5.6} md={12}>
            <AutoCompleteInput
              label={t("form.selection.manufacture-year.title")}
              listOption={listManufactured}
              onChange={setSelectedManufactured}
              value={selectedManufactured}
              disabled={selectedModel && selectedModel.id !== "" ? false : true}
              placeholder={t("form.selection.manufacture-year.placeholder")}
              open={
                selectedModel && selectedModel.id !== "" && openCarManufactured
              }
              onClose={() => {
                setOpenCarManufactured(false);
                setOpenCarVersion(true);
              }}
              onOpen={() => {
                setOpenCarManufactured(!openCarManufactured);
                setOpenCarVersion(false);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={5.6} md={12}>
            <AutoCompleteInput
              label={t("form.selection.version.title")}
              listOption={listVersion}
              onChange={setSelectedVersion}
              value={selectedVersion}
              disabled={
                selectedManufactured && selectedManufactured.id !== ""
                  ? false
                  : true
              }
              placeholder={t("form.selection.version.placeholder")}
              open={
                selectedManufactured &&
                selectedManufactured.id !== "" &&
                openCarVersion
              }
              onClose={() => {
                setOpenCarVersion(false);
              }}
              onOpen={() => {
                setOpenCarVersion(!openCarVersion);
              }}
            />
          </Grid>
          {listOption.length > 0 && (
            <Grid item xs={12} sm={5.6} md={12}>
              <AutoCompleteInput
                label="Option"
                listOption={listOption}
                onChange={setSelectedOption}
                value={selectedOption}
                placeholder="Select an option"
                open={openCarOption}
                onClose={() => {
                  setOpenCarOption(false);
                }}
                onOpen={() => {
                  setOpenCarOption(true);
                }}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={12}>
            <Button
              sx={{
                textTransform: "unset",
                border: "none",
                color: "#4E65FF",
                ":hover": {
                  border: "none",
                  backgroundColor: "transparent",
                },
              }}
              onClick={() => setExpandMore(!expandMore)}
              variant="outlined"
              endIcon={
                !expandMore ? <IconExpandInformation /> : <IconHideInfomation />
              }
            >
              Thông tin bổ sung{" "}
            </Button>
          </Grid>
          {expandMore && (
            <>
              {listOrigin.length > 0 && (
                <Grid item xs={12} sm={5.6} md={12}>
                  <AutoCompleteInput
                    label="Xuất xứ"
                    listOption={listOrigin}
                    onChange={setSelectedOrigin}
                    value={selectedOrigin}
                    placeholder="Xuất xứ"
                    open={openCarOrigin}
                    onClose={() => {
                      setOpenCarOrigin(false);
                    }}
                    onOpen={() => {
                      setOpenCarOrigin(true);
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={5.6} md={12}>
                <FormControl variant="standard" sx={{ minWidth: "100%" }}>
                  <Typography
                    sx={{
                      fontFamily: "San Francisco Text",
                      fontWeight: 400,
                      fontSize: "14px",
                      padding: "5px 0px",
                      color: theme.palette.mode === "dark" ? "white" : "black",
                    }}
                  >
                    Số km đã đi
                  </Typography>
                  <TextField
                    value={onRoad}
                    placeholder="Số km đã đi"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">Km</InputAdornment>
                      ),
                    }}
                    size="small"
                    onChange={handleChangeOnRoad}
                    type="number"
                  />
                  {selectedManufactured && selectedManufactured.id !== "" && (
                    <FormHelperText>
                      Đây là gợi ý số km đã đi trung bình theo tuổi xe của bạn
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </>
          )}
          <Button
            style={{
              width: "100%",
              height: "50px",
              background: `${isValidate ? "#5768FF" : "#f0f0f0"}`,
              borderRadius: "12px",
              fontWeight: 700,
              fontSize: "16px",
              lineHeight: "20px",
              textAlign: "center",
              letterSpacing: "0.005em",
              color: `${isValidate ? "#ffffff" : "#bdbdbd"}`,
              textTransform: "unset",
              wordBreak: "keep-all",
              display: "block",
              margin: "0 auto",
            }}
            disabled={!isValidate}
            type="submit"
          >
            Tra cứu
          </Button>
        </>
      )}
    </>
  );
});

export default CarForm;
